import './style.css';
import "ol-ext/dist/ol-ext.css"

import { Map, View, Feature} from 'ol';
import TileState from 'ol/TileState';
import Tile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import proj4 from 'proj4';
import * as olproj from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { applyTransform } from 'ol/extent';
import Attribution from 'ol/control/Attribution';
import ScaleLine from 'ol/control/ScaleLine';
import Graticule from 'ol-ext/control/Graticule';
import { Graticule as OLGraticule, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { defaults as controlDefaults } from 'ol/control';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Polygon from 'ol/geom/Polygon';
import {unByKey} from 'ol/Observable';
import WebGLTileLayer from 'ol/layer/WebGLTile.js';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';


function work() { 
// Set up Proj4.

// Define UTM zone 32.
proj4.defs('EPSG:23032', '+proj=utm +zone=32 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs');

// Define EPSG:25832 projection and register it with open layers.
proj4.defs('EPSG:25832', "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ");

// Register.
register(proj4);

// Set extent of UTM
const utm_projection = olproj.get('EPSG:23032')
utm_projection.setExtent([-1206118.71, 4021309.92, 1295389.00, 8051813.28]);

// Set extent of ESPG
const epsg_projection = olproj.get('EPSG:25832');
const grad_transform = olproj.getTransform('EPSG:4326', epsg_projection);

// Very approximate calculation of projection extent
const worldExtent = [7, 54, 16, 58];  // [6, 38.76, 12, 83.92] 
epsg_projection.setWorldExtent(worldExtent);

const extent = applyTransform(worldExtent, grad_transform);
epsg_projection.setExtent(extent);

// Set the attribution (the copyright statement shown in the lower right corner)
// We do this as we want the same attributions for all layers.

const date = new Date();
const date_string = '' + date.getDate() + '/' + (date.getMonth() + 1) + ' - ' + date.getFullYear(); 
const attribution_text = '&copy; <a target="_blank" href="https://dataforsyningen.dk/asset/PDF/rettigheder_vilkaar/Vilk%C3%A5r%20for%20brug%20af%20frie%20geografiske%20data.pdf">Styrelsen for Dataforsyning og Effektivisering</a><br/>DTK/Kort25 genereret på k.muspelheim.org';


const source25 = new TileWMS({
  attributions: attribution_text,
  url: "https://api.dataforsyningen.dk/dtk_25_DAF?service=WMS&request=GetCapabilities&token=3b9b91c466b50c7a97fcd07f2c272f3f",
  params: {
    'VERSION': '1.1.1',
    'TRANSPARENT': 'false',
    'LAYERS': 'dtk25',
    'FORMAT': 'image/png',
    'STYLES': '' 
  },
  crossOrigin: '',
})


function tileLoadFunction(imageTile, src) {
    const image = imageTile.getImage();
    const timer = setTimeout(() => {
      imageTile.setState(TileState.ERROR);
    }, 4000); // Timeout load error in milliseconds.
    image.addEventListener('load', () => clearTimeout(timer));
    image.addEventListener('error', () => clearTimeout(timer));
    image.src = src;
}

source25.setTileLoadFunction(tileLoadFunction)

const topo25 = new Tile({
  title: 'dtk_25',
  visible: true,
  type: 'base',
  opacity: 1.0,
  source: source25
});


// Function for converting points.
function points(x, y, dims, resolution) {
  const width = dims[0] * resolution;
  const height = dims[1] * resolution;
  const tl = [x - width/2, y - height/2];
  const tr = [x + width/2, y - height/2];
  const br = [x + width/2, y + height/2];
  const bl = [x - width/2, y + height/2];

  return [tl, tr, br, bl, tl];
}


function createUTMGridGraticule(target_size) {
  // Create scaled graticule.
  let font_size = Math.round(Math.pow(0.1 * target_size, 0.8)).toString();

  let style = new Style();

  style.setStroke(new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      width: Math.round(Math.pow(target_size / 200, 0.7)),
  }));

  // Disables border.
  // style.setFill (new Fill({
  //   color: "#000"
  // }));

  style.setText(new Text({
    font: font_size + 'px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(0,0,0,0.8)',
    }),
    stroke: new Stroke({
      color: 'rgba(255,255,255,0.7)',
      width: 3 * target_size / 200,
    }),
  }));

  // Graticule.
  let graticule = new Graticule({ 
    step: 1000, 
    stepCoord: 1,
    projection: epsg_projection,
    targetSize: target_size,
    style: style,
    wrapX: true,
    visible: true,
    showLabels: true,
    margin: 4,
    formatCoord: function(value, position) { return parseInt(value / 1000); }
  });

  return graticule
}

const graticule_utm = createUTMGridGraticule(200);


function createGradGraticule(target_size) {
  // Create scaled graticule.
  let font_size = Math.round(Math.pow(0.1 * target_size, 0.8)).toString();

  let lon_label_style = new Text({
    font: font_size + 'px Calibri,sans-serif',
    textBaseline: 'bottom',
    fill: new Fill({ color: 'rgba(100, 150, 180, 1)' }),
    stroke: new Stroke({
      color: 'rgba(255,255,255,0.8)',
      width: 3 * target_size / 200,
    }),
  });

  let lat_label_style = new Text({
    font: font_size + 'px Calibri,sans-serif',
    textAlign: 'end',
    fill: new Fill({
      color: 'rgba(100, 150, 180, 1)',
    }),
    stroke: new Stroke({
      color: 'rgba(255,255,255,0.8)',
      width: 3 * target_size / 200,
    }),
  });

  // division of seconds and minutes.
  let s = 1/60 - 0.0000000000000001;

  // Graticule.
  let graticule = new OLGraticule({
    strokeStyle: new Stroke({
        color: 'rgba(130, 180, 210, 1)',
        width: Math.round(Math.pow(target_size / 200, 0.7)),
    }),
    intervals: [90, 45, 30, 20, 10, 5, 2, 1, 0.5, 10 * s, 5 * s, 2 * s, 1 * s, 0.5 * s, 0.1 * s, 0.05 * s, 0.01 * s],
    lonLabelPosition: 0.02,
    latLabelPosition: 0.99,
    lonLabelStyle: lon_label_style,
    latLabelStyle: lat_label_style,
    showLabels: true,
    targetSize: target_size,
    wrapX: true,
    visible: true,
  });

  return graticule;
}

const graticule_grad = new createGradGraticule(200);


// Try to retrieve params.
function getFloat(value, default_value) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return default_value;
  }
  return value;
}

let params = new window.URL(window.location.href).searchParams;
let center = [
  getFloat(params.get('x'), 537200), 
  getFloat(params.get('y'), 6152800)
];
let zoom = getFloat(params.get('z'), 4);


// Set up map
const map = new Map({
  layers: [topo25],
  target: 'map',
  pixelRatio: 1,
  // turn off the default attribution control as we will create a new one later on.
  controls: controlDefaults({ attribution: false }), 
  view: new View({
    zoom: zoom,
    projection: epsg_projection,  // Custom projection.
    center: center,    // start center position
  }),
});

// Add uncollapsible attribution.
map.addControl(new Attribution({ collapsible: false })); // add a custom attribution 


// Scale bar.
const scale_bar = new ScaleLine({
  bar: true,
  text: true,
  minWidth: 125
});
map.addControl(scale_bar);

// Add loading spinner.
map.once('loadstart', function () {
  map.getTargetElement().classList.add('spinner');
  // document.getElementById('export-pdf').disabled = true;

  map.once(['loadend', 'rendercomplete'], function () {
    map.getTargetElement().classList.remove('spinner');
    // document.getElementById('export-pdf').disabled = false;
  });
});


/**
 * Renders a progress bar.
 */
function Progress(el) {
  this.el = el;
  this.loading = 0;
  this.loaded = 0;
  this.hiding = false;
}

Progress.prototype.reset = function() {
  this.loading = this.loading - this.loaded;
  this.loaded = 0;
  this.hiding = false;
}

Progress.prototype.addLoading = function () {
  ++this.loading;
  this.update();
};

Progress.prototype.addLoaded = function () {
  ++this.loaded;
  this.update();
};

Progress.prototype.update = function () {
  const width = ((this.loaded / this.loading) * 100).toFixed(1) + '%';
  this.el.style.width = width;
};

Progress.prototype.show = function () {
  this.el.style.visibility = 'visible';
  this.hiding = false;
};

Progress.prototype.hide = function () {
  this.hiding = true;
  setTimeout(this._doHide.bind(this), 250);
}

Progress.prototype._doHide = function () {
  if (this.hiding === false) {
      return;
  }
  const style = this.el.style;
  style.visibility = 'hidden';
  style.width = 0;
}

const progress = new Progress(document.getElementById('progress'));


// Connect progress to source loading.
let source = topo25.getSource();

source.on('tileloadstart', function () {
  progress.addLoading();
});
source.on(['tileloadend', 'tileloaderror', 'tileerror'], function () {
  progress.addLoaded();
});

map.on('loadstart', function () {
  progress.reset();
  progress.show();
});
map.on('loadend', function () {
  progress.hide();
});

// DIN A paper dimensions.
const dims = {
  a0: [1189, 841],
  a1: [841, 594],
  a2: [594, 420],
  a3: [420, 297],
  a4: [297, 210],
  a5: [210, 148],
};

var feature = new Feature({
  geometry: new Polygon(
    [points(537200, 6152800, [297 - 16, 210 - 16], 25)]
  )
});

feature.setStyle(new Style({
  stroke: new Stroke({
      color: 'rgba(50, 180, 50, 1)',
      width: 3.5,
  }),
  fill: new Fill({
    color: 'rgba(50, 200, 50, 0.3)'
  }),
}));
  
const paper_overlay = new VectorLayer({
  source: new VectorSource({
    features: [feature],
  }),
  opacity: 0.7,
  updateWhileInteracting: true,
  updateWhileAnimating: true,
});

map.addLayer(paper_overlay);

function updatePaperOverlay() {
  // Update paper overlay.
  const center = map.getView().getCenter();
  const scale = form.scale_select.value;
  let dimensions = dims[form.format_select.value];
  if (!form.orientation_checkbox.checked) {
      dimensions = [dimensions[1], dimensions[0]];
  }
  feature.setGeometry(new Polygon([points(center[0], center[1], dimensions, scale)]));
  paper_overlay.changed();
}


// Scale.
function Form() {
  this.scale_select = document.getElementById('scale');
  this.format_select = document.getElementById('format');
  this.orientation_checkbox = document.getElementById('orientation');
  this.resolution_select = document.getElementById('resolution');
  this.utmnet_checkbox = document.getElementById('utmnet');
  this.gradnet_checkbox = document.getElementById('gradnet');
  this.address_element = document.getElementById('map-address');
  this.export_button = document.getElementById('export-pdf');
  this.cancel_button = document.getElementById('cancel-export');
}


Form.prototype.updateFromURLParams = function(params) {
  // Update the form from the uri params.
  let scale = params.get('s', '25000');
  if (scale) {
    scale = parseInt(scale) / 1000;
    if ([10, 25, 50, 100, 250, 500].includes(scale)) {
      this.scale_select.value = scale;
    }
  }

  const format = params.get('f', 'a4');
  if (['a5', 'a4', 'a3', 'a2', 'a1', 'a0'].includes(format)) {
    this.format_select.value = format;
  }
  
  const orientation = params.get('o', 'liggende');
  if (orientation == 'staaende') {
    this.orientation_checkbox.checked = false;
  }
  
  const resolution = params.get('dpi', '200');
  if (['72', '100', '120', '150', '200', '300'].includes(resolution)) {
    this.resolution_select.value = parseInt(resolution);
  }

  if (params.get('utm', false) == 'ja') {
    this.utmnet_checkbox.checked = true;
  }

  if (params.get('grad', false) == 'nej') {
    this.gradnet_checkbox.checked = false;
  }
}


function updateURLParams() {
  // Replace the url search with the current value.
  if ('URLSearchParams' in window === false) {
    return;
  }

  // Do no update params while exporting.
  if (window.exporting === true){
    return;
  }

  var searchParams = new URLSearchParams();
  let view = map.getView();

  let center = view.getCenter();
  let zoom = view.getZoom();

  searchParams.set("x", center[0].toFixed(3));
  searchParams.set("y", center[1].toFixed(3));
  searchParams.set("z", zoom.toFixed(3));
  
  // Get some form elements.
  let scale = form.scale_select.value;
  if (scale != 25) {
    searchParams.set("s", scale * 1000)
  }

  let format = form.format_select.value;
  if (format != 'a4') {
    searchParams.set("f", format)
  } 

  let resolution = form.resolution_select.value;
  if (resolution != 200) {
    searchParams.set("dpi", resolution)
  } 

  let orientation = form.orientation_checkbox.checked;
  if (orientation === false) {
    searchParams.set("o", 'staaende')
  } 
  
  let utmnet_checked = form.utmnet_checkbox.checked;
  if (utmnet_checked === true) {
    searchParams.set("utm", "ja");
  } 

  let gradnet_checked = form.gradnet_checkbox.checked;
  if (gradnet_checked === false) {
    searchParams.set("grad", "nej");
  } 

  const url = new URL(window.location.href);
  url.search = searchParams.toString();

  history.replaceState(null, '', url.href);

  url.hash = 'kort';
  form.address_element.href = window.location.href;
  form.address_element.innerHTML = window.location.href.substring(
    window.location.href.indexOf('/') + 2
  );
}


Form.prototype.elements = function() {
  // Retrieve all form elements.
  return [
    this.scale_select,
    this.format_select,
    this.orientation_checkbox,
    this.resolution_select,
    this.gradnet_checkbox,
    this.utmnet_checkbox
  ];
}

let form = new Form();
form.updateFromURLParams(params);

form.elements().forEach(function(element){
  element.addEventListener('change', updatePaperOverlay);
  element.addEventListener('change', updateURLParams);
})

function updateGradNet() {
  if (form.gradnet_checkbox.checked) {
    map.addLayer(graticule_grad);
  } else {
    map.removeLayer(graticule_grad);
  }
}

function updateUTMNet() {
   if (form.utmnet_checkbox.checked) {
    map.addControl(graticule_utm);
  } else {
    map.removeControl(graticule_utm);
  }
}

form.gradnet_checkbox.addEventListener('change', updateGradNet);
form.utmnet_checkbox.addEventListener('change', updateUTMNet);

updateGradNet();
updateUTMNet();


// Also connect map to both changes.
map.on('moveend', (event) => {
    updateURLParams();
    updatePaperOverlay();
});

// Always update paper overlay.
map.getView().on('change:center', updatePaperOverlay);


// Export options for html 2canvas.
// See: https://html 2canvas.hertzen.com/configuration
const export_options = {
  // inlineImages: false -> Only used when testing html 2canvas, not needed.
  useCORS: true,
  scale: 0.5,  //Math.min(window.devicePixelRatio, 2),
  // foreignObjectRendering: true,  -> renders black images.
  ignoreElements: function (element) {
    const class_name = element.className || '';
    return !(
      class_name.indexOf('ol-control') === -1 ||
      class_name.indexOf('ol-scale') > -1 ||
      (class_name.indexOf('ol-attribution') > -1 && class_name.indexOf('ol-uncollapsible'))
    );
  },
};

// Export elements.
const wrapper = document.getElementById('wrapper');


let scaled_graticule_grad = false;
let scaled_graticule_utm = false;
let view_resolution = 1;
let dim = [0, 0];
let export_event = undefined;


function exportToPDF() {
  // Disable.
  disableInteractions();

  // Get settings.
  const format = form.format_select.value;
  const resolution = 2 * form.resolution_select.value;
  const scale = form.scale_select.value;
  const has_utm_net = form.utmnet_checkbox.checked;
  const has_grad_net = form.gradnet_checkbox.checked;

  // Dim.
  dim = dims[format];
  if (!form.orientation_checkbox.checked) {
    dim = [dim[1], dim[0]];
  }

  // Scale.
  var width = Math.round((dim[0] * resolution) / 25.4);
  var height = Math.round((dim[1] * resolution) / 25.4);
  
  export_options.width = width;
  export_options.height = height;

  // Scale.
  view_resolution = map.getView().getResolution();
  const scale_resolution = 
    scale /
    olproj.getPointResolution(
      map.getView().getProjection(),
      resolution / 25.4,
      map.getView().getCenter()
    );

  // Build scaled grids.
  scaled_graticule_grad = has_grad_net ? createGradGraticule(200 / 72 * resolution) : false;
  scaled_graticule_utm = has_utm_net ? createUTMGridGraticule(200 / 72 * resolution) : false;

  // Set wrapper font size.
  wrapper.style.fontSize = Math.floor(resolution / 72 * 12).toString() + 'px';

  map.removeLayer(paper_overlay);

  // Change garticule.
  if (has_grad_net) {
    map.addLayer(scaled_graticule_grad);
    map.removeLayer(graticule_grad);
  }
  // Set print size
  scale_bar.setDpi(resolution);

  // Change map size.
  map.getTargetElement().style.width = width + 'px';
  map.getTargetElement().style.height = height + 'px';

  map.updateSize();

  // Wait for map to finish rendering.
  export_event = map.once('rendercomplete', saveMapToPDF);
  
  // refresh.
  topo25.getSource().refresh();
  topo25.getSource().changed();
  
  // Change resolution.
  map.getView().setResolution(scale_resolution);

  // Add scaled graticule.
  if (has_utm_net) {
    map.addControl(scaled_graticule_utm);
    map.removeControl(graticule_utm);
  }

  // Show cancel button.
  form.cancel_button.style.visibility = 'visible';
}


// Add cancel export functionality.
function cancelExport(){
  if (export_event) {
    unByKey(export_event);
    enableInteractions();  
  }
}

form.cancel_button.addEventListener('click', cancelExport);


function saveMapToPDF() {
  export_event = undefined;

  // Perform html 2canvas conversion and then save pdf.
  html2canvas(map.getViewport(), export_options).then(function (canvas) {
    const pdf = new jsPDF(
      form.orientation_checkbox.checked ? 'landscape' : 'portrait', 
      undefined, 
      form.format_select.value
    );
    pdf.addImage(
      canvas.toDataURL('image/jpeg'),
      'JPEG',
      8,
      8,
      dim[0],
      dim[1]
    );
    pdf.save('map.pdf');
    // Re-enable interactions.
    enableInteractions();
  });
}


function disableInteractions() {
  window.exporting = true;

  // Show progress cursor.
  document.body.style.cursor = 'progress';

  // Disable button
  form.export_button.disabled = true;
  form.cancel_button.style.visibility = 'visible';

  // Disable map interactions
  let interactions = map.getInteractions();
  for (var i=0; i<interactions.getLength(); i++) {
    interactions.getArray()[i].setActive(false); 
  }

  // Add class.
  wrapper.classList.add('wrapper-export');

  // Disable some form elements. 
  form.elements().forEach(function(element){
    element.disabled = true;
  });
  
}


function enableInteractions() {
  // Reset to original map size.
  scale_bar.setDpi();
  if (scaled_graticule_grad) {
    map.removeLayer(scaled_graticule_grad);
    map.addLayer(graticule_grad);
  }
  if (scaled_graticule_utm) {
    map.removeControl(scaled_graticule_utm);
    map.addControl(graticule_utm);
  }

  map.addLayer(paper_overlay);

  wrapper.style.fontSize = '';
  wrapper.classList.remove('wrapper-export');

  map.getTargetElement().style.width = '';
  map.getTargetElement().style.height = '';
  map.getTargetElement().style.fontSize = '';

  map.updateSize();
  map.getView().setResolution(view_resolution);

  // Re-enable interactions.
  let interactions = map.getInteractions();
  for (var i=0; i<interactions.getLength(); i++) {
    interactions.getArray()[i].setActive(true); 
  }

  // Enable some form elements. 
  form.elements().forEach(function(element){
    element.disabled = false;
  });

  form.export_button.disabled = false;

  document.body.style.cursor = 'auto';

  window.exporting = false;

  form.cancel_button.style.visibility = 'hidden';
}

form.export_button.addEventListener('click', exportToPDF);
}

if (document.readyState == 'loading') {
  // still loading, wait for the event
  document.addEventListener('DOMContentLoaded', work);
} else {
  // DOM is ready!
  work();
}
